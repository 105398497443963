global.$ = jQuery;
global.jQuery = jQuery;

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")

require("bootstrap")

require("slick-carousel")
import "slick-carousel/slick/slick.scss"

import $ from 'jquery';
import { Form } from 'packs/form';

$(window).bind('popstate', function(){
  window.location.href = window.location.href;
});

$(document).on("turbolinks:load", function(){
  try { Form.initialize() } catch(err) { console.log("form errors") }
  $(".show-contact-form").click(function() {
    $("#contact-form").css("display", "flex")
  })

  var offers_slick = $('.slick-section .slick-list')
  offers_slick.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    $(".slick-section .paging-info").text(i + '/' + slick.slideCount);
  });
  offers_slick.slick({})

  var yt_slick = $('.slick-yt-section .slick-list')
//yt_slick.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
//  var i = (currentSlide ? currentSlide : 0) + 1;
//  $(".slick-yt-section .paging-info").text(i + '/' + (slick.$dots[0].children.length));
//});
  yt_slick.slick({
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  })
 
  $('.arrange-test-drive').click(function() {
    $('.form form').last().find("select[name*=topic]").val($(this).data("topic"))
    $('html,body').animate({
      scrollTop: $('.form form').last().offset().top
    }, 'slow');
  })

  $(".slick-details .slick-list").slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '100px',
  })
});

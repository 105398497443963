export const Form = {
  email_regex: /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,

  initialize: function() {   
    $("input[name*=phone_number]").keypress( function(e) {
      return /[0-9 +/()]/.test(e.key)
    });
    $("input[name*=email]").keypress( function(e) {
      return /\S/.test(e.key)
    });

    $("#contact-form").click(function(e) {
      if (e.target.id == 'contact-form') {
        $('#contact-form').hide()
      }
    });

    $(".show-term").click(function() {
      var css_class = $(this).data('class')
      var box = $(this).parents('form').find('.'+css_class)
      if (box.is(':visible')) {
        box.slideUp()
      } else {
        box.slideDown()
      }
    })

    $(".form form").on('ajax:beforeSend', function() {
      $(this).find('.form-group').removeClass('invalid')

      if ($(this).find('select[name*=topic]').val() == null) {
        $(this).find('select[name*=topic]').parents('.form-group').addClass('invalid')
      }
      if ($(this).find('select[name*=location]').val() == null) {
        $(this).find('select[name*=location]').parents('.form-group').addClass('invalid')
      }
      if ($("#vehicle-repurchase form").length) {
        if ($(this).find('input[name*=brand]').val() == "") {
          $(this).find('input[name*=brand]').parents('.form-group').addClass('invalid')
        }
      } else {
        if ($(this).find('select[name*=brand]').val() == null) {
          $(this).find('select[name*=brand]').parents('.form-group').addClass('invalid')
        }
      }
      if ($(this).find('input[name*=first_name]').val() == "") {
        $(this).find('input[name*=first_name]').parents('.form-group').addClass('invalid')
      }
      if ($(this).find('input[name*=last_name]').val() == "") {
        $(this).find('input[name*=last_name]').parents('.form-group').addClass('invalid')
      }
      if ($(this).find('input[name*=mileage]').val() == "") {
        $(this).find('input[name*=mileage]').parents('.form-group').addClass('invalid')
      }
      if ($(this).find('[name*=topic]').val() == "Akcja techniczna") {
        var vin_wrapper = $(this).find('input[name*=vin_number]').parents('.form-group')
        if ($(this).find("[name*=vin_number]").val() == "") {
          vin_wrapper.addClass('invalid')
          vin_wrapper.find('.error').text("Proszę podać numer VIN")
        } else if ($(this).find("[name*=vin_number]").val().length != 17 ) {
          vin_wrapper.addClass('invalid')
          vin_wrapper.find('.error').text("Nieprawidłowa długość, powinna wynosić 17 znaków")
        }
      }

      if ($(this).find('input[name*=email]').val() == "") {
        $(this).find('input[name*="[email]"]').parents('.form-group').addClass('invalid')
        $(this).find('input[name*="[email]"]').parents('.form-group').find("span.error").text("Proszę podać email")
      } else if (!Form.email_regex.test($(this).find('input[name*=email]').val())) {
        $(this).find('input[name*="[email]"]').parents('.form-group').addClass('invalid')
        $(this).find('input[name*="[email]"]').parents('.form-group').find("span.error").text("Błędny format adresu email")
      }

      if ($(this).find('input[name*=phone_number]').val() == "") {
        $(this).find('input[name*="[phone_number]"]').parents('.form-group').addClass('invalid')
        $(this).find('input[name*="[phone_number]"]').parents('.form-group').find("span.error").text("Proszę podać nr telefonu")
      } else if (!/^[0-9 +/()]+$/.test($(this).find('input[name*=phone_number]').val())) {
        $(this).find('input[name*="[phone_number]"]').parents('.form-group').addClass('invalid')
        $(this).find('input[name*="[phone_number]"]').parents('.form-group').find("span.error").text("Błędny format nr telefonu")
      }

      //if (!$(this).find("input[name*=term_1_email][type=checkbox]").prop("checked") && !$(this).find("input[name*=term_2_phone][type=checkbox]").prop("checked")) {
      //  $(this).find("input[name*=term_1_email]").parents('.form-group').addClass('invalid')
      //  $(this).find("input[name*=term_2_phone]").parents('.form-group').addClass('invalid')
      //}

      //if (!$(this).find("input[name*=term_7_information_obligation][type=checkbox]").prop("checked")) {
      //  $(this).find("input[name*=term_7_information_obligation]").parents('.form-group').addClass('invalid')
      //}

      //if ($(this).find(".form-group.invalid input[name*=term]").length && !$(this).find(".terms").is(":visible")) {
      //  $(this).find(".terms").slideDown()
      //}

      if ($(this).find('.form-group.invalid').length) {
        if ($(this).parents("#contact-form").length) {
          $('form').animate({scrollTop: 0}, 'slow');

        } else {
          $('html, body').animate({scrollTop:$(this).find('.form-group.invalid').first().offset().top-20}, 'slow');
        }
        return false
      } else {
        return true
      }
    });

    $(".form form").submit(function() {
      $(this).append($('<input>', { type: 'hidden', name: 'recaptcha_token', value: $("#recaptcha_badge").attr("data-recaptcha-token") }))
    })

    $(".form form").on('ajax:success', function() {
      var hash = {}
      var data = []
      $(this).find("[class*='yl-']").each(function(i, input) {
        var class_name = $(input).attr('class').split(" ").filter(function(i,y) { return i.indexOf('yl-') === 0 })[0]
        if ($(input).is(':radio')) {
          hash[class_name] = ($('.'+class_name+':checked').val() == "1")
        } else if ($(input).is(':checkbox')) {
          hash[class_name] = $(input).prop("checked")
        } else {
          hash[class_name] = $(input).val()
        }
      })
      $.each(hash, function(k,v) { data.push({[k]: v}) })
      try { Monitor.TrackForm($(this).attr('id'), 3, 2, data) } catch(err) { console.log("YL errors") }

      $(this).find('input.form-control:not([type=hidden])').val("")
      $(this).find("select,textare").val("")
      $(this).find("input[type=checkbox]").prop("checked", false)
      $(this).find("#files .file").remove()
      $(this).find(".close").click()
      $("#note_success_form").show()
      $("#note_success_form").append('<script type="text/javascript">var getCookie=function(e){for(var t=e+"=",r=document.cookie.split(";"),n=0;n<r.length;n++){for(var s=r[n];" "==s.charAt(0);)s=s.substring(1);if(0==s.indexOf(t))return s.substring(t.length,s.length)}return""},getUserId=function(){var e=getCookie("twd_u");return""===e?"":e},userId=getUserId(),xhr=new XMLHttpRequest;xhr.open("GET",https://trafficscanner.pl/ws2/index.php/?action=noscript&dt=77&cid=2996&user_id=+userId),xhr.send(); </script><script type="text/javascript" src=https://trafficscanner.pl/ws2/script/setSaleCookie.js></script> ')
      window.getCaptchaToken();
    })

    $('.move-to-form').click(function() {
      $('html,body').animate({
        scrollTop: $('.form form').last().offset().top
      }, 'slow');
    })

    $('.show-popup-form').click(function(){
      var options = []
      $("#contact-form form").attr("id", $(this).data('list'))
      $("#contact-form #client-topic option").remove()
      $("#contact-form .vin_number-wrapper").addClass("d-none")
      if ($(this).data('list') == 'main') {
        options = [
          "Wybierz temat wiadomości",
          "Jazda testowa",
          "Pojazdy nowe",
          "Pojazdy używane",
          "Odkup pojazdu",
          "Oferta finansowania",
          "Akcja techniczna",
          "Usługi serwisowe",
          "Inne zapytanie do działu obsługi klienta",
        ]
        $("#contact-form #client-topic").change(function() {
          if ($(this).val() == "Akcja techniczna") {
            $("#contact-form .vin_number-wrapper").removeClass("d-none")
          } else {
            $("#contact-form .vin_number-wrapper").addClass("d-none")
            $("#contact-form .vin_number-wrapper").removeClass("invalid")
          }
        });
      } else if ($(this).data('list') == 'financing') {
        options = [
          "Leasing",
          "Comfort leasing",
          "Kredyt",
          "Ubezpieczenia komunikacyjne",
          "Service Inclusive",
          "Assistance",
        ]
      } else if ($(this).data('list') == 'detailing') {
        options = [ "Detailing" ]
      } else if ($(this).data('list') == 'loyalty_program') {
        options = [ "Program lojalnościowy" ]
      } else if ($(this).data('list') == 'parts') {
        options = [
          "Skontaktuj się z doradcą",
          "Oryginalne części i akcesoria",
        ]
      } else if ($(this).data('list') == 'fleet_center') {
        options = [
          "BMW 318d Gran Turismo - LP Centrum Flotowe",
          "BMW 518d - LP Centrum Flotowe",
          "BMW X3 20d xDrive - LP Centrum Flotowe",
          "BMW 330e - LP Centrum Flotowe",
          "BMW 530e xDrive - LP Centrum Flotowe",
          "BMW i3 - LP Centrum Flotowe"
        ]
      } else if ($(this).data('list') == 'vehicles') {
        options = [ "Pojazdy" ]
      }

      $.each(options, function(i,o) {
        $("#contact-form #client-topic").append(new Option(o, o));
      })

      if ($(this).data('list') == 'main') {
        $("#contact-form #client-topic option").first().attr("disabled",true)
      } else {
        $("#contact-form #client-topic").val($(this).data('subject'))
      }

      if ($(this).data('location')) {
        $("#contact-form select[name='client[location]']").val($(this).data('location'))
      } else {
        $("#contact-form select[name='client[location]']").val("")
      }

      if ($(this).data('description')) {
        $("#contact-form [name='client[description]']").val($(this).data('description'))
      } else {
        $("#contact-form [name='client[description]']").val("")
      }

      $("#contact-form #client_form_kind").val($(this).data('list'))
      $("#contact-form").css('display', 'flex')
    })


  }
}
